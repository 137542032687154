@font-face {
  font-family: MuseoSans;
  font-weight: 100;
  src: url("/assets/exljbris - MuseoSans-100.otf");
}

@font-face {
  font-family: MuseoSans;
  font-weight: 300;
  src: url("/assets/exljbris - MuseoSans-300.otf");
}

@font-face {
  font-family: MuseoSans;
  font-weight: 500;
  src: url("/assets/exljbris - MuseoSans-500.otf");
}

@font-face {
  font-family: MuseoSans;
  font-weight: 700;
  src: url("/assets/exljbris - MuseoSans-700.otf");
}

@font-face {
  font-family: MuseoSans;
  font-weight: 900;
  src: url("/assets/exljbris - MuseoSans-900.otf");
}

:root {
  --black: #000;
  --almost-black: #111;
  --consumer-pink: #dc1895;
  --consumer-orange: #ffa300;
  --business-blue: #005eb8;
  --charcoal-grey: #4d4d4f;
  --input-grey: #aaa;
  --light-grey: #ddd;
  --error-red: #c60f13;
  --br-grey: #ddd;
}

body {
  margin: 0;
  padding: 0;
  font-family: MuseoSans, sans-serif;
  font-size: 16px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

a {
  color: var(--consumer-pink);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

blockquote,
h1,
h2,
h3,
hr,
p {
  -ms-grid-column: 4;
  -ms-grid-column-span: 6;
  grid-column: 4/10;
}

@media (max-width: 1024px) {
  blockquote,
  h1,
  h2,
  h3,
  p,
  span {
    -ms-grid-column: 2;
    -ms-grid-column-span: 10;
    grid-column: 2/12;
  }
}

h2,
h3,
hr,
p {
  margin: 0;
  margin-bottom: 40px;
}

@media (max-width: 1024px) {
  h2,
  h3,
  hr,
  p {
    margin-bottom: 30px;
  }
}

h1 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 300;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 24px;
    line-height: 28px;
  }
}

h2 {
  font-size: 22px;
}

h2,
h3 {
  line-height: 25px;
  font-weight: 500;
}

h3 {
  font-size: 18px;
}

p,
span {
  line-height: 25px;
}

blockquote {
  font-size: 36px;
  font-weight: 100;
  text-align: center;
  padding: 20px 0;
  margin: 40px 0 80px;
  color: var(--charcoal-grey);
  position: relative;
}

blockquote:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 100%;
  width: 75%;
  border-top: 1px solid var(--light-grey);
  border-bottom: 1px solid var(--light-grey);
}

@media (max-width: 1024px) {
  blockquote {
    margin: 30px 0 60px;
  }
}

hr {
  border: solid 1px var(--light-grey);
  margin-bottom: 40px;
  width: 100%;
  max-width: 590px;
}

@media (max-width: 1024px) {
  hr {
    margin-bottom: 30px;
  }
}

i {
  font-style: normal;
}

li {
  line-height: 25px;
  font-size: 16px;
}

figure {
  margin: 40px 0 80px;
}

@media (max-width: 1024px) {
  figure {
    margin: 30px 0 60px;
  }
}

figure {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/13;
  -ms-grid-column-align: center;
  justify-self: center;
}

figure img {
  width: 100%;
  margin: 0;
  max-width: 1190px;
}

figure figcaption {
  float: right;
  font-size: 11px;
  color: var(--charcoal-grey);
}

.index_centerChildren {
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  justify-content: center;
}

.index_wrap {
  max-width: 990px;
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-items: center;
}

.index_wide {
  max-width: 1190px;
}

.index_text {
  background: #fff;
  position: relative;
}

@media (max-width: 1024px) {
  .index_text {
    padding: 15px;
  }
}

.index_grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: (90px) [12];
  grid-template-columns: repeat(12, 90px);
  grid-column-gap: 10px;
}

@media (max-width: 1024px) {
  .index_grid {
    -ms-grid-columns: (1fr) [12];
    grid-template-columns: repeat(12, 1fr);
  }
}

@import "./article_meta.css";
@import "./articles.css";
@import "./button.css";
@import "./card.css";
@import "./cta.css";
@import "./editors_picks.css";
@import "./event_post.css";
@import "./event.css";
@import "./featured_article.css";
@import "./footer.css";
@import "./header.css";
@import "./home.css";
@import "./nav.css";
@import "./page.css";
@import "./pagination.css";
@import "./related_articles.css";
@import "./search.css";
@import "./social.css";
@import "./text_post.css";
@import "./video_post.css";
